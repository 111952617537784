<template>
  <v-col cols="12" md="5" lg="5" :class="{'pa-4': $vuetify.breakpoint.mobile, 'pr-3': !$vuetify.breakpoint.mobile}">
    <v-card
      outlined
      tile
      class="mb-3 mt-md-4"
    >
      <v-card-title class="pt-3 pb-5 px-8">
        <v-icon
          color="secondary"
          left
          small
        >
          mdi-map-marker-radius-outline
        </v-icon>
        Geolocalización
      </v-card-title>
      <v-card-text class="px-8">
        <v-row>
          <v-col cols="12" md="6" lg="6" class="mb-0 py-0">
            <!-- <label-form text="Latitud" required/> -->
            <v-text-field
              v-model="places.lat"
              outlined
              dense
              hide-details
              readonly
              prefix="LAT"
              class="position-map"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="mb-0 py-0">
            <!-- <label-form text="Longitud" required/> -->
            <v-text-field
              v-model="places.lng"
              outlined
              dense
              hide-details
              readonly
              prefix="LNG"
              class="position-map"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="$hasPermission('clientes.geolocalizacion.editar')">
          <v-col cols="12" class="text-center mt-3">
            <v-btn block color="secondary"  @click="updateMarker" :loading="updating">
              <v-icon left>mdi-map-marker-plus-outline</v-icon>
              Actualizar Ubicación
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div style="position:relative">
      <div class="container-geolocation">
        <v-row justify="center">
          <v-col cols="12" class="pt-4 px-6">
            <div class="v-input v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined white">
              <div class="v-input__control">
                <div class="v-input__slot mb-0 white elevation-2">
                  <fieldset style="top: -2px;" aria-hidden="true"><legend style="width: 0px;"></legend></fieldset>
                  <div class="v-text-field__slot">
                    <gmap-autocomplete placeholder="Buscar dirección"  @place_changed="setPlace">
                    </gmap-autocomplete>
                  </div>
                  <div class="v-input__append-inner">
                    <div class="v-input__icon v-input__icon--append">
                      <v-btn color="secondary" plain depressed class="px-2 mt-n1"  @click="addMarker">
                        <v-icon>mdi-map-search</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
          <!-- <v-card class="pa-0 ma-0">
            <v-card-text class="pa-0">
            </v-card-text>
          </v-card> -->
        <!-- <v-col cols="12" class="mx-auto pa-0">
        </v-col> -->
      </div>
    </div>
      <gmap-map
        :center="center"
        :zoom="15"
        :options="{
          streetViewControl: false,
          rotateControl: false,
          disableDefaultUI: false,
        }"
        :style="styleMap"
        ref="mapRef"
        @click="getPosition"
      >
        <gmap-marker
          :position="markers.position"
          :clickable="true"
          :draggable="false"
          @click="center=markers.position"
          :icon="require('@/assets/Markers/marker_m1_verde.png')"
        >

        </gmap-marker>

      </gmap-map>

  </v-col>
</template>
<script>
export default {
  name: 'TabDetailsMap',
  props:{
    lat: {
      type: Number,
      default: '',
    },
    lng: {
      type: Number,
      default: '',
    },
    idCliente: String
  },
  data: () => ({
    center: { lat: 8.955582, lng: -65.3220065 },
    markers: {},
    places:
    {
      lat: '',
      lng: '',
    },
    updating: false,
    currentPlace: null
  }),
  mounted() {
    console.log(this.lat, this.lng);
    if(this.lat != null && this.lng != null){
      this.center = {
        lat: this.lat,
        lng: this.lng,
      };
      this.markers = { position: this.center };
      this.places = this.center ;
    }
    else
      this.geolocate();
  },

  methods: {
    getPosition(e){
      this.markers = { position: {lat: e.latLng.lat(), lng: e.latLng.lng() } };
      this.places.lat = e.latLng.lat();
      this.places.lng = e.latLng.lng();
    },
    async updateMarker() {
      this.updating = true;
      try {
        const { data } = await this.$api.put(`clientes/${this.idCliente.trim()}`, {...this.places, _method: 'put'});
        this.$emit('updateLocation');
        this.$root.$showAlert(data.message, 'success');
      } catch (error) {
        this.$root.$showAlert(
          'Lo sentimos, hubo un error al intentar actualizar la Ubicación del Cliente.',
          'error'
        );
      }
      this.updating = false;
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers = { position: marker };
        this.places = marker;
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
      // this.panToMarker();
    }
  },
  computed:{
    styleMap(){
      return {
        width: '100%',
        height: this.$vuetify.breakpoint.mobile ? '100vh' : '100%',
        'margin-right': '12px'
      }
    }
  }
}
</script>
<style lang="sass">
.container-geolocation
  position: absolute
  top: 3.5rem
  z-index: 999
  width: 100%
.position-map .v-text-field__prefix
  font-weight: 400
</style>
